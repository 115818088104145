<template>
  <q-dialog v-model="isShowDialog" full-width persistent>
    <q-card>
      <q-card-section class="row justify-between">
        <h6>Questões do banco de dados</h6>
        <q-btn
          flat
          round
          icon="close"
          clickeble
          color="default-pink"
          v-close-popup
        />
      </q-card-section>
      <q-card-section class="row flex">
        <div class="col-12 row flex wrap">
          <QuestionCard
            v-for="question in dataBaseQuestions"
            :key="question.questionId"
            :questionData="question"
            :title="question.description_question"
            @selected-item="(e) => $emit('showQuestions', e)"
          />
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Salvar" color="default-pink" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import QuestionCard from "@/components/courses/cards/QuestionCard";

import { ref, watch } from "vue";

export default {
  name: "DialogQuestion",
  props: {
    dataBaseQuestions: Array,
    showDialog: Boolean,
  },
  components: {
    QuestionCard,
  },
  emits: ["showQuestions"],
  setup(props) {
    let isShowDialog = ref(props.showDialog);

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      isShowDialog,
    };
  },
};
</script>

<style lang="scss" scoped></style>