import http from '../http-common'

export default class GenerateEvaluationDataServices{
    async getAll() {
        return await http.get("v1/course/14/training/17/quiz/11");
    }

    async getQuiz(courseId, trainingId, quizId) {
        return await http.get(`v1/course/${courseId}/training/${trainingId}/quiz/${quizId}`);
    }

    async getQuestionsStorage() {
        return await http.get(`v1/question`);
    }

    async saveQuiz(courseId, trainingId, quiz){
        return await http.post(`v1/course/${courseId}/training/${trainingId}/quiz`, quiz);
    }

    async updateQuiz(courseId, trainingId, quizId, quiz){
        return await http.put(`v1/course/${courseId}/training/${trainingId}/quiz/${quizId}`, quiz);
    }

    async saveQuestion(question){
        return await http.post("v1/question", question);
    }

    async updateQuestion(question){
        return await http.put("v1/question", question);
    }

    async anulatedQuestion(questionId){
        return await http.put(`v1/question/${questionId}/anulated`);
    }


}