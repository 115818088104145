<template>
   <q-btn 
      color="default-pink"
      :text-color="outline == true ? 'default-pink' :  'white'" 
      rounded 
      no-caps
      :outline="outline"
      unelevated
      type="submit"
    >
      <span >
        <q-img
          width="18px"
          :src="saveIcon"          
          spinner-color="white"
        />
      </span>
      <p style="margin: 0px 10px">{{titleButton}}</p>
    </q-btn>
</template>


<script>
//-----IMAGES
import saveIcon from '@/assets/icons/save.svg';

export default {
  name: 'FormButton',
  props:{
    titleButton:String,
    outline:{
       type: Boolean,
      default: false
    }
  },
  setup(){
    return{
      //----IMAGES
      saveIcon
    }
  }
}
</script>


<style lang="scss" scoped>

button {
    box-shadow: 3px 10px 15px #00000033;
    padding: 8px 15px;

    ::before {
      box-shadow: none;
    }
  }

  button span {
    background: #ffffff33;
    margin-right: 5px;
    padding: 2px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button p {
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0px 10px!important;
  }
</style>