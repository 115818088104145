<template>
  <TabDefault
    :tab="1"
    :label-tab="questions.options"
    @addNewTab="() => $emit('addNewQuestion', genarateNewQuestion())"
    btn-new-tab
  >
    <template v-slot:cards-tabs>
      <div
        :id="question.question.id"
        class="content-tabs"
        v-for="question in questions.options"
        :key="question.question.id"
      >
        <div class="card-tabs col-12">
          <div class="card-tab-header col-12 row flex">
            <div class="col-3 q-pt-sm flex justify-center">
              <label class="label-question"
                >Questão {{ question.question.id }}</label
              >
            </div>
            <div class="col-3 row flex">
              <q-input
                v-if="isPointValue"
                v-model.number="question.point_value"
                bg-color="grey-3"
                class="col-md-10"
                dense
                input-style="border-radius: 8px;"
                type="number"
                outlined
                maxlength="4"
                reverse-fill-mask
                unmasked-value
                :disable="
                  question.isQuestionFromDataBase == null && quizId != null
                "
                lazy-rules
                :rules="[(val) => val != '' || 'Campo obrigatório']"
              >
                <template v-slot:before>
                  <label class="label-peso">Pontos</label>
                </template>
              </q-input>
              <q-input
                v-else
                v-model.number="question.weight"
                bg-color="grey-3"
                class="col-md-10"
                dense
                input-style="border-radius: 8px;"
                type="number"
                outlined
                maxlength="4"
                reverse-fill-mask
                unmasked-value
                :disable="
                  question.isQuestionFromDataBase == null && quizId != null
                "
                lazy-rules
                :rules="[(val) => val != '' || 'Campo obrigatório']"
              >
                <template v-slot:before>
                  <label class="label-peso">Peso</label>
                </template>
              </q-input>
            </div>
            <div class="col-3">
              <q-select
                v-model="question.question.option_type"
                outlined
                dense
                options-dense
                emit-value
                map-options
                :disable="
                  question.isQuestionFromDataBase == true ||
                  (question.isQuestionFromDataBase == null && quizId != null)
                "
                :options="optionAlternatives"
                bg-color="grey-3"
              />
            </div>
            <div class="col-3 flex justify-end">
              <q-btn
                danse
                icon="o_delete"
                @click="
                  () =>
                    $emit('removeQuestion', {
                      id: question.question.id,
                      removeOrAnulated:
                        question.isQuestionFromDataBase == null &&
                        quizId != null,
                    })
                "
                unelevated
                rounded
              />
              <q-btn
                danse
                icon="folder_special"
                :disable="
                  question.isQuestionFromDataBase == null && quizId != null
                "
                @click="
                  () => $emit('getQuestionsDatabase', question.question.id)
                "
                unelevated
                rounded
              >
                <q-tooltip class="bg-default-pink text-white"
                  >Banco de questões</q-tooltip
                >
              </q-btn>
            </div>
          </div>
          <div class="card-tab-body q-mt-sm col-12 flex row">
            <q-input
              v-model="question.question.description_question"
              :disable="
                question.isQuestionFromDataBase == true ||
                (question.isQuestionFromDataBase == null && quizId != null)
              "
              class="col-12"
              dense
              :placeholder="
                question.question.option_type == 1 ||
                question.question.option_type == 2
                  ? 'Digite o título da atividade.'
                  : 'Digite o enunciado da questão...'
              "
              input-style="border-radius: 8px;"
              type="textarea"
              outlined
              lazy-rules
              :rules="[(val) => val != '' || 'Campo obrigatório']"
            />
          </div>
          <div class="card-tab-footer col-12">
            <div
              v-if="
                question.question.option_type == 1 ||
                question.question.option_type == 2
              "
            >
              <div
                class="container-alternatives col-12 q-mt-sm row"
                v-for="option in question.question.options"
                :key="option.id"
              >
                <div class="col-1">
                  <q-radio
                    :disable="
                      question.isQuestionFromDataBase == true ||
                      (question.isQuestionFromDataBase == null &&
                        quizId != null)
                    "
                    v-if="question.question.option_type == 1"
                    @click="
                      question.isQuestionFromDataBase == false &&
                        selectAwnser(option, question)
                    "
                    v-model="option.correct_question"
                    :val="true"
                    color="default-green-check"
                  />
                  <q-checkbox
                    v-else
                    :disable="
                      question.isQuestionFromDataBase == true ||
                      (question.isQuestionFromDataBase == null &&
                        quizId != null)
                    "
                    v-model="option.correct_question"
                    checked-icon="check_circle"
                    unchecked-icon="radio_button_unchecked"
                    color="default-green-check"
                    keep-color
                  >
                  </q-checkbox>
                </div>
                <div class="col-11 container-input-alternative">
                  <q-input
                    v-model="option.description_option"
                    :disable="
                      question.isQuestionFromDataBase == true ||
                      (question.isQuestionFromDataBase == null &&
                        quizId != null)
                    "
                    class="col-12"
                    clearable
                    clear-icon="close"
                    dense
                    counter
                    maxlength="200"
                    color="default-pink"
                    placeholder="Descreva a questão."
                    type="text"
                    outlined
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </TabDefault>
</template>

<script>
import TabDefault from "@/components/tabs/DefaultTabs.vue";

export default {
  name: "FromQuestionEvaluation",
  props: {
    questions: Object,
    optionAlternatives: Array,
    isPointValue: {
      type: Boolean,
      default: false,
    },
    quizId: {
      type: Number,
      default: null,
    },
  },
  components: {
    TabDefault,
  },
  emits: ["addNewQuestion", "removeQuestion", "getQuestionsDatabase"],
  setup(props) {
    function genarateNewQuestion() {
      let schemaQuestion = {
        options: {
          weight: 0,
          point_value: 0,
          question_id: 0,
          isQuestionFromDataBase: false,
          question: {
            id: 1,
            questionId: 1,
            description_question: "",
            option_type: 1,
            enabled_quiz: true,
            enabled_evaluation: false,
            options: [
              {
                id: _generateUUIDv4(),
                description_option: "",
                correct_question: false,
              },
              {
                id: _generateUUIDv4(),
                description_option: "",
                correct_question: false,
              },
              {
                id: _generateUUIDv4(),
                description_option: "",
                correct_question: false,
              },
              {
                id: _generateUUIDv4(),
                description_option: "",
                correct_question: false,
              },
              {
                id: _generateUUIDv4(),
                description_option: "",
                correct_question: false,
              },
            ],
          },
        },
      };
      return schemaQuestion;
    }

    function _generateUUIDv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    }

    function selectAwnser(option, question) {
      let options = question.question.options;

      for (let index = 0; index < options.length; index++) {
        if (options[index].id == undefined) {
          if (options[index].questionOptionId == option.questionOptionId) {
            options[index].correct_question = true;
          } else {
            options[index].correct_question = false;
          }
        } else {
          if (options[index].id == option.id) {
            options[index].correct_question = true;
          } else {
            options[index].correct_question = false;
          }
        }
      }
    }

    return {
      genarateNewQuestion,
      selectAwnser,
    };
  },
};
</script>

<style lang="scss" scoped>
.material-questions {
}

.title-info {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
}

.info-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.content-tabs {
  display: none;
  flex: 1;
  height: 100%;

  padding: 6px 12px;
  border: 1px solid #e3e8eb;
  border-radius: 20px;
  margin: 0% 3%;
  background: #fafafa;
}

.card-tabs {
  // height: 60vh;
  width: 100%;

  .card-tab-header {
    align-items: flex-start;
    .label-question {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #2f2f2f;
    }

    .label-peso {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #9c9c9c;
    }

    .error {
      border: solid 1px red;
      input {
        border: solid 1px red;
      }
    }
  }

  .card-tab-body {
    background-color: #ffffff !important;

    .card-body-title {
      width: 100%;
      height: 48px;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 8px;

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #686868;
      }
    }
  }

  .card-tab-footer {
    .container-input-alternative {
      background-color: #ffffff;
    }
  }
}
</style>
